<template>
    <h1>
        {{title}}
    </h1>
</template>

<script>
export default {
    name:"PageTitle",
    props: ['title'],
}
</script>

<style lang="scss" scoped>
h1{
    text-align: center;
    margin: 3.2rem auto;
    font-size: 2.55rem;
    font-weight: 500;
}
</style>