<template>
    <div id="contact">
        <PageTitle :title="title" />
        <div class="container">
            <div class="image-container">
                <img src="/images/contact-image.jpg" alt="Contact Background">
            </div>
            <div id="info">
                <h2>
                    <a class="contact" :href="'mailto:'+email" >{{email}}</a>
                </h2>
                <h2>
                   <a class="contact" :href="'tel:' + phone"> {{phone}}</a>
                </h2>
                <hr />
                <p v-for="item in address" :key="item">
                    {{item}}
                </p>
                <p style="padding-top:2rem;">
                    DRE #: {{drenumber}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>

import PageTitle from "../components/PageTitle"

export default {
    name: "Contact",
    components: {
        PageTitle,
    },
    data(){
        return{
            title: "Contact",
            marker: {
                lat: 34.066722, 
                lng: -118.398450,
            },
            email: "hannah@thehannahgroup",
            phone: "(310) 902-1090",
            // address: {
            //     street: "Weichert Realtors | The Collective",
            //     suite: "3355 Lenox Road, Suite 320",
            //     city: "Atlanta, GA 30326",
            // },
            drenumber: "01479408"
        }
    },
    metaInfo: {
        title: 'Contact | Hannah DelPonte Vollera',
        meta: [{
            name: 'description', 
            content: "Westside & Beach Cities Luxury Real Estate."
        }]
    },
}
</script>

<style lang="scss" scoped>
.container{
    display: flex;
    margin-bottom: 75px;
    .image-container{
        width: 50%;
        img{
            display: block;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
    #info{
        flex: 1;
        margin: 2rem 4rem;
        *{
            text-transform: uppercase;
        }
        h2 {
            margin: 1.5rem 0;
            font-size: 1rem;
            a{
                color: #000;
            }
        }
        hr{
            border: none;
            height: 2px;
            /* Set the hr color */
            color: #000; /* old IE */
            background-color: #000; /* Modern Browsers */
            width: 4rem;
            margin: 2.5rem 0;
        }
        p{
            padding: 0;
        }
    }
}
@media (max-width: $tablet) {
    .container{
        flex-wrap: wrap-reverse;
        #info{
            margin: 0 2rem 2rem 2rem;
            text-align: center;
            hr{
                margin: 2.5rem auto;
            }
        }
        .image-container{
            width: 100%;
        }
    }
}
</style>